<template>
  <section>
    <div class="account-section">
      <img class="avatar" src="/images/professionals/professional-2.png" />
      <p class="name">Rodrigo Almeida</p>
    </div>
    <form>
      <home-input
        label="Título do projeto"
        name="title"
        type="text"
        :value="title"
        @change="handleChange"
      />
      <home-input
        label="Breve descrição do projeto"
        name="description"
        type="text"
        :value="description"
        @change="handleChange"
      />
      <div class="addSection">
        <p>Adicione ou arraste imagens em: JPEG, PNG ou GIF até 5MB</p>
        <div ref="imagePreview" class="imageBox" @click="uploadImage()" />
        <input type="file" accept="image/*" ref="inputImage" @change='changeImage' />
      </div>
    </form>
    <div class="bottom-container">
      <div class="buttons-container">
        <home-button type="primary" @click.native="submitForm()">
          Salvar
        </home-button>
      </div>
    </div>
    <div class="image-container">
      <img class="creators-logo" src="/images/C.png" alt="Logo creators" />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      TOKEN: this.$store.state.token,
      title: "",
      description: "",
    };
  },
  methods: {
    changeImage(e) {
      const { imagePreview } = this.$refs;
      imagePreview.style.backgroundImage = `url('${URL.createObjectURL(event.target.files[0])}')`
      imagePreview.style.backgroundSize = 'cover'
    },
    uploadImage() {
      const { inputImage } = this.$refs;
      inputImage.click();
    },
    handleChange(field, value) {
      this[field] = value;
    },
    async submitForm() {
      var _this = this;
      const { inputImage } = this.$refs;
      const formData = new FormData();

      formData.append("file", inputImage.files[0]);
      formData.append("name", this.title);
      formData.append("notes", this.description);

      const response = await axios({
        method: "POST",
        url: "/api/v1/works",
        headers: {
          Authorization: "Bearer " + _this.TOKEN,
        },
        data: formData
      });
    }
  }
};
</script>
<style lang="scss" scoped>
section {
  @media screen and (max-width: 768px) {
    height: calc(100%);
  }

  position: relative;
  font-family: "SF Pro Display";
  min-height: 100vh;
  .account-section {
    @media screen and (max-width: 768px) {
      margin-bottom: 0px;
      margin-top: 22px;
    }
    height: 48px;
    display: flex;
    align-items: center;
    margin-bottom: 132px;
    img {
      margin-right: 24px;
      height: 100%;
    }
    .name {
      color: white;
      font-size: 18px;
      line-height: 24px;
    }
  }
  form {
    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 0;
    }
    width: 70%;
    padding: 0 10%;
    * {
      margin: 48px 0;
    }
    .addSection {
      width: max-content;
      margin-bottom: 30px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      input {
        display: none;
      }
      p {
        font-size: 20px;
        margin: 0;
        font-family: "SF Pro Display";
        color: white;
      }
      .imageBox {
        @media screen and (max-width: 768px) {
          margin: 8px 0;
        }
        cursor: pointer;
        background-image: url("/images/camera.png");
        margin: 31px 0;
        background-color: #7f7f7f;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 10px;
        width: 400px;
        height: 200px;
      }
    }
  }
  .bottom-container {
    height: auto;
    bottom: 10.5%;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 0px;
    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
    button {
      margin: 0;
    }
    a {
      text-decoration: none;
    }
    position: absolute;
    /* @media screen and (max-width: 768px) {
      position: absolute;
    } */
  }
  .image-container {
    margin-bottom: 0px;
    opacity: 0.4;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
      display: none;
    }
  }
  .creators-logo {
    width: 43px;
  }
}
</style>
