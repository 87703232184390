<template>
  <section>
    <div class="account-section">
      <img class="avatar" :src="avatar" />
      <p class="name">{{ author }}</p>
    </div>
    <div class="textual-content">
      <div class="title-container">
        <h1 class="title">{{ name }}</h1>
      </div>
      <div class="notes-container">
        <p class="project-notes">{{ notes }}</p>
      </div>
    </div>
    <div class="image-content">
      <div 
        v-for="file in files" 
        :key="file.id" 
        :id="!file.url.includes('vimeo') ? file.id : `vimeo-player-${file.id}`" 
        :class="!file.url.includes('vimeo') ? 'image-container' : 'vimeo-player'"
      >
        <img v-if="!file.url.includes('vimeo')" :src="`${file.url}`" alt="first picture" />
      </div>
      <div class="image-container">
        <img class="creators-logo" src="/images/C.png" alt="Logo creators" />
      </div>
    </div>
  </section>
</template>

<script>
import Player from '@vimeo/player'

export default {
  props: ['work', 'user'],
  data() {
    return {
      // TOKEN: this.$store.state.token,
      // me: this.$store.state.me,
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.work.files.forEach(file => {
        if (file.url.includes('vimeo')) {
          const options = {
            loop: true,
            title: false,
            byline: false,
            portrait: false
          }

          const player = new Player(`vimeo-player-${file.id}`, {...options, url: file.url})
        }
      })
    })
  },

  computed: {
    files() {
      if (this.work) {
        if (this.work.files.length) return this.work.files;
        if (this.work.file) return [{url: '/' + this.work.file}];
      }
      return [];
    },
    name() {
      if (this.work) return this.work.name;
      return '';
    },
    notes(){
      if (this.work) return this.work.notes;
      return '';
    },
    author(){
      if (this.user) return this.user.name;
      return '';
    },
    avatar(){
      if (this.user) return this.user.avatar ? '/' + this.user.avatar : '/images/avatar.jpg';
      return '';
    },
  },

  methods: {
  },
};
</script>

<style lang="scss" scoped>
section {
  font-family: "SF Pro Display";
  height: 100%;
  .account-section {
    @media screen and (max-width: 768px) {
      margin-top: 22px;
      margin-bottom: 51px;
    }
    height: 48px;
    display: flex;
    align-items: center;
    margin-bottom: 132px;
    img {
      margin-right: 24px;
      height: 100%;
    }
    .name {
      color: white;
      font-size: 18px;
      line-height: 24px;
    }
  }
  .textual-content {
    @media screen and (max-width: 768px) {
      padding: 0;
    }
    padding: 0 10%;
    margin-bottom: 28px;
    .title {
      @media screen and (max-width: 768px) {
        font-size: 24px;
      }
      display: inline-block;
      color: white;
      font-size: 32px;
      line-height: 38px;
    }
    .project-notes {
      @media screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 28px;
      }
      color: white;
      font-family: "SF Pro Display";
      font-size: 32px;
      line-height: 38px;
    }
  }
  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  .edit-input {
    background-color: transparent;
    color: white;
    border: 0px;
    border-bottom: 2px solid #7053d9;
    font-family: "SF Pro Display";
    font-size: 16px;
    width: auto;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  textarea {
    background-color: transparent;
    width: 90%;
    line-height: initial;
    font-family: "SF Pro Display";
    font-size: 26px;
    color: #fff;
    border: 0px;
    border-bottom: 2px solid #7053d9;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  .image-content {
    @media screen and (max-width: 768px) {
      width: 100vw;
      margin-left: -16px;
      margin-bottom: 70px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    .image-container {
      @media screen and (max-width: 768px) {
        margin: 0;
        width: 100%;
      }
      margin: 28px 0;
      img {
        width: 100%;
      }
      .creators-logo {
        @media screen and (max-width: 768px) {
          width: 0px;
        }
        width: 43px;
        opacity: 0.4;
      }
    }
  }
  .avatar {
    border-radius: 50%;
    width: 48px;
    height: 48px;
  }

  .vimeo-player {
    @media (max-width: 768px) {
      height: calc(100vw / 1.77);
      padding: 0;
      margin-top: 12px;
    }

    width: 100%;
    height: calc(80vw / 1.77);
    padding: 0 10%;
  }
}
</style>
