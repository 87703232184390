
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import { required, email } from "vuelidate/lib/validators";
import Vue from "vue";
import Vuelidate from "vuelidate";
import moment from "moment";

Vue.use(Vuelidate);

export default {
  name: "Header",
  props: ["user"],
  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      company: {},
      community: {
        users: [],
      },
      response: {},
      lang: "pt",
      name: "",
      habilitiesList: [],
      major: "",
      complementary: [],
      creatorSince: "",
      skills: [],
      bio: "",
      creatorUrl: "",
      email: "",
      phone: "",
      site: "",
      network: {},
      communities: [],
      reviews: [],
      location: "",
      works: [],
      site: "",
      backgroundIndex: Math.round(Math.random() * (3 - 1) + 1),
      backgrounds: [],
      expanded: false,
      copied: false,
      added: false,
      removed: false,
      loaderSize: "25px",
      loaderColor: "white",
      loading: true,
      isMemberInCommunity: false,
      projects: "",
    };
  },
  components: {
    SyncLoader,
  },
  computed: {
    randomImage() {
      if (this.user.background) {
        return `linear-gradient(rgba(0, 0, 0, 0.7) 80%, rgba(0, 0, 0, 1)),
        url('/${this.user.background}')`;
      }
      return `linear-gradient(rgba(0, 0, 0, 0.7) 80%, rgba(0, 0, 0, 1)),
      url('/images/banners/creators-freelancer-nosotros-0${this.backgroundIndex}.jpg')`;
    },
    averageReviews() {
      var sum = 0;
      var ammount = 0;
      if (this.reviews != null && this.reviews.length)
        this.reviews.forEach((review) => {
          sum += review.total;
          ammount++;
        });
      if (ammount > 0) return (sum / ammount);
      return 0;
    },
    avatar() {
      return this.user.avatar ? "/" + this.user.avatar : "/images/avatar.jpg";
    },
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
      email,
    },
  },
  mounted() {
    var _this = this;

    if (this.me.company_id) {
      axios
        .get("/api/v1/companies/" + this.me.company_id, {
          headers: {
            Authorization: "Bearer " + this.TOKEN,
          },
        })
        .then((response) => {
          if (!response.data.error) {
            _this.company = response.data.data;
            _this.community = _this.company.community;
            axios
              .get("/api/v1/communities/" + _this.company.community.id, {
                headers: {
                  Authorization: "Bearer " + this.TOKEN,
                },
              })
              .then((response) => {
                if (!response.data.error) {
                  _this.community = response.data.data;
                  if (_this.community.users.find((e) => e.id === this.user.id))
                    _this.isMemberInCommunity = true;
                } else {
                  console.log(response.data.error);
                }
              });
          } else {
            console.log(response.data.error);
          }
        });
    }

  },
  methods: {

    copyText() {
      const { textToCopy } = this.$refs;
      textToCopy.select();
      document.execCommand("copy");
      this.copied = true;
      setTimeout(() => (this.copied = false), 2000);
    },

    removeMember() {
      axios
        .delete(
          "/api/v1/communities/" +
            this.community.id +
            "/members/" +
            this.user.id,
          {
            headers: {
              Authorization: "Bearer " + this.TOKEN,
            },
          }
        )
        .then((response) => {
          if (response.data.error) {
            // _this.errors = response.data.error;
            console.log(response.data.error);
          } else {
            let index = this.community.users
              .map((e) => {
                return e.id;
              })
              .indexOf(this.user.id);
            if (index > -1) this.community.users.splice(index, 1);
          }
        });

      this.isMemberInCommunity = !this.isMemberInCommunity;
      this.added = false;
      this.removed = true;
      setTimeout(() => (this.removed = false), 2000);
    },

    addMember() {
      axios
        .post(
          "/api/v1/communities/" + this.community.id + "/members",
          { userId: this.user.id },
          {
            headers: {
              Authorization: "Bearer " + this.TOKEN,
            },
          }
        )
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
          }
        });

      this.isMemberInCommunity = !this.isMemberInCommunity;
      this.removed = false;
      this.added = true;
      setTimeout(() => (this.added = false), 2000);
    },

    subscriberPlans() {
      this.$router.push({ path: "/dashboard/client/mycommunity-notamember" });
    },
  },

  watch: {
    user() {
      var _this = this;
      const data = _this.user;
      const skillsList = data.specialities.map((e) => e[this.lang]);
      // if (data.profile.major) {
      //   skillsList.unshift(data.profile.major);
      // }
      this.skills = skillsList;
      this.works = data.works;
      this.backgrounds = data.works.map((work) => work.file);
      this.complementary = data.specialities.map((e) => e[this.lang]);
      this.major = data.profile.major ? data.profile.major : "";
      this.bio = data.profile.bio;
      if (this.bio != null && this.bio.length > 185)
        this.bio = this.bio.substring(0, 185) + "...";
      this.site = data.profile.site;
      // this.creatorUrl = `creators.llc/c/${data.id}/${data.name}`;
      this.creatorUrl = `https://creators.llc/c/${data.id}/${data.name.split(" ")[0]}`;
      this.name =
        data.profile && data.profile.perfil ? data.profile.perfil : data.name;
      this.phone = data.profile.phone;
      this.network = data.network;
      this.communities = data.communities;
      this.reviews = data.received_reviews;
      this.creatorSince = moment(data.created_at).format("DD/MM/YYYY");
      if (data && data.profile && data.profile.country) {
        if (data.profile.country_id == 3)
          this.location = data.profile.city && data.profile.country ? `${data.profile.city.name}, ${data.profile.country.name}` : " ";
        else
          this.location = data.profile.country ? `${data.profile.city_open}, ${data.profile.country.name}` : data.profile.city_open || " ";
      }
      this.loading = false;

      let matchingHirings = data.hirings
        .filter((hiring) => hiring.company_id === _this.me.company_id)
        .map((e) => e.title);
      this.projects = matchingHirings.join();
    },
  },
};
