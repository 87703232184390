import Header from "./-Header.vue";
import Projects from "./-Projects.vue";
import Project from "./ModalsContent/-Project.vue";
import CreateProject from "./ModalsContent/-CreateProject.vue";
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';

export default {
  
  components: {
    Header,
    Project,
    CreateProject,
    Projects
  },

  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      modal: null,
      project: {},
      user: {
        profile: {
          country: {},
        },
        works: [],
        specialities: [],
        received_reviews: [],
        communities: [],
        network: {},
      },
      instagram: null,
      social: {
        instagram: null,
        youtube: null,
      },
      posts: [],
    };
  },

  mounted() {
    var _this = this;
    axios
      .get('/api/v1/users/' + _this.$route.params.id)
      .then(response => {
        if (!response.data.error) {
          _this.user = response.data.data;
          console.log(_this.user);
          // _this.posts = _this.user.instagram_posts;

          // setTimeout(function(){
          //   if (window.instgrm) window.instgrm.Embeds.process();
          // }, 500);
          
        } else {
          console.log(response.data.error);
        }
      });
  },

  methods: {
    handleModal(type) {
      this.modal = type;
    },
    setProject(project) {
      this.project = project;
    }
  }
};
